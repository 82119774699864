import React from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Hidden,
  IconButton,
  useMediaQuery
} from "@material-ui/core";
import { Link } from "react-router-dom";

import Logout from "../Logout";
// import logo from "../../assets/images/logo-white.svg";

import MenuIcon from "@material-ui/icons/Menu";

const TopBar = ({ onMobileNavOpen, disableHamburgerMenu = false }) => {
  const isDesktop = useMediaQuery("(min-width:1400px)", {
    defaultMatches: true
  });
  return (
    <AppBar elevation={0} color="primary" position="fixed">
      <Toolbar>
        <Box
          display="flex"
          flex="1 0 auto"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" component={Link} to="/">
            {/*
            <img width={120} height={26} src={logo} alt="capten" />
            */}
            <Box>
              <span style={{ color: "#fff", fontSize: 30, paddingRight: 8 }}>
                uniten
              </span>
              <span style={{ color: "#fff", fontSize: 18 }}>
                content performance
              </span>
            </Box>
          </Box>
          <Logout />
        </Box>
        {!disableHamburgerMenu && (
          <Hidden lgUp={isDesktop}>
            <IconButton color="inherit" onClick={() => onMobileNavOpen()}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
