import React, { useState, Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, CircularProgress, Table } from "@material-ui/core";
import AccountsTableHead from "./components/AccountsTableHead";
import AccountsTableBody from "./components/AccountsTableBody";
import AccountsTableLoading from "./components/AccountsTableLoading";
import AccountsTablePagination from "./components/AccountsTablePagination";
import ConfirmAccountDeletionDialog from "../ConfirmAccountDeletionDialog/ConfirmAccountDeletionDialog";

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  }
}));

const fullViewLoadingComponent = (
  <div
    style={{
      position: "absolute",
      zIndex: 110,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "rgba(255,255,255,0.8)"
    }}
  >
    <CircularProgress size={24} />
  </div>
);

const AccountsTable = ({
  className,
  accounts = [],
  loading,
  isDeleting,
  deleteItem,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalItemsCount,
  editItemBaseUrl,
  canEditPolicy,
  canDeletePolicy
}) => {
  const classes = useStyles();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const handlePageChange = (_, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const confirmItemDelete = id => {
    setDeleteItemId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteItemFeedback = feedback => {
    if (feedback) {
      deleteItem(deleteItemId);
    }
    setDeleteDialogOpen(false);
    setDeleteItemId(null);
  };

  return (
    <Card className={clsx(classes.root, className)}>
      <ConfirmAccountDeletionDialog
        open={deleteDialogOpen}
        handleFeedback={handleDeleteItemFeedback}
      />
      <Fragment>
        <CardContent className={classes.content}>
          <div className={classes.inner}>
            <AccountsTableLoading loading={loading} />
            <div style={{ position: "relative" }}>
              {isDeleting && fullViewLoadingComponent}
              <Table>
                <AccountsTableHead />
                <AccountsTableBody
                  canEditPolicy={canEditPolicy}
                  canDeletePolicy={canDeletePolicy}
                  editItemBaseUrl={editItemBaseUrl}
                  deleteItem={confirmItemDelete}
                  loading={loading}
                  items={accounts}
                />
              </Table>
            </div>
          </div>
        </CardContent>
        <AccountsTablePagination
          page={page}
          rowsPerPage={rowsPerPage}
          loading={loading}
          length={totalItemsCount}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </Fragment>
    </Card>
  );
};

AccountsTable.propTypes = {
  className: PropTypes.string,
  accounts: PropTypes.array.isRequired,
  loading: PropTypes.bool
};

export default AccountsTable;
