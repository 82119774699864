import React, { useState, useEffect } from "react";
import EditCustomer from "./EditCustomer";
import EditCustomerLayout from "./EditCustomerLayout";
import { useApi } from "../../api";
import { useHistory } from "react-router-dom";
import { keysToSnake } from "../../utils/stringUtils";

export default function EditCustomerContainer({ customerId }) {
  const history = useHistory();
  const api = useApi();
  const [isSubmitting, setSubmitting] = useState(false);
  const [commonUrl] = useState(`/v1/customers/${customerId}`);
  const [customer, setCustomer] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    setSubmitting(true);
    api
      .get(commonUrl)
      .then(response => {
        const { data } = response;
        setCustomer(keysToSnake(data));
        setSubmitting(false);
      })
      .catch(error => {
        setError(error);
        setSubmitting(false);
      });
  }, [api, commonUrl]);

  const onSubmit = values => {
    const requestObj = { address: {} };
    requestObj.name = values.name;
    delete values.name;
    Object.keys(values).map(key => (requestObj.address[key] = values[key]));
    setSubmitting(true);
    setError(null);
    api
      .put(commonUrl, requestObj)
      .then(() => {
        setSubmitting(false);
        history.goBack();
      })
      .catch(error => {
        const { errors } = error.response.data;
        setSubmitting(false);
        errors ? setError(errors[0].msg) : setError(error.message);
      });
  };

  return (
    <EditCustomerLayout
      customer={customer}
      error={error}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      FormComponent={EditCustomer}
    />
  );
}
