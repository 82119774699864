import { useContext } from "react";

import DataStudioReportsContext from "../context/DataStudioReportsContext";

const useDataStudioReports = () => {
  const context = useContext(DataStudioReportsContext);
  if (context === undefined) {
    throw new Error(
      "useDataStudioReports must be used within a DataStudioReportsContext"
    );
  }

  return context;
};

export default useDataStudioReports;
