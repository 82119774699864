import React from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import { NAV_ID } from "../../navigation";

export default function BreadcrumbItem({ items, navigationName = "" }) {
  const history = useHistory();

  const handleClick = (event, link) => {
    event.preventDefault();
    history.push(link);
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {items &&
        items.map((breadcrumb, index) => {
          return index === items.length - 1 ? (
            <Typography key={index} color="textPrimary">
              {breadcrumb.title === NAV_ID ? navigationName : breadcrumb.title}
            </Typography>
          ) : (
            <Link
              key={index}
              color="inherit"
              href={breadcrumb.link && breadcrumb.link}
              onClick={e => handleClick(e, breadcrumb.link)}
            >
              {breadcrumb.title === NAV_ID ? navigationName : breadcrumb.title}
            </Link>
          );
        })}
    </Breadcrumbs>
  );
}
