import React from "react";
import { List } from "@material-ui/core";
import I18nText from "../../../../elements/I18nText";

//import DataUsage from "@material-ui/icons/DataUsage";
import SpeedIcon from '@material-ui/icons/Speed';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import PeopleIcon from '@material-ui/icons/People';
//import MapIcon from '@material-ui/icons/Map';
//import AccessTimeIcon from '@material-ui/icons/AccessTime';
//import TodayIcon from '@material-ui/icons/Today';
//import PeopleIcon from '@material-ui/icons/People';
//import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
//import FacebookIcon from '@material-ui/icons/Facebook';
//import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
//import SpeedIcon from '@material-ui/icons/Speed';
//import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
//import MemoryIcon from "@material-ui/icons/Memory";
//import GroupIcon from "@material-ui/icons/Group";
import SideBarNavItem from "./components/SideBarNavItem";
import { useHistory } from "react-router-dom";
import User from "../../../../models/User";

const appMenuItems = [
  /*
  {
    name: <I18nText>sideBar.dashboard.title</I18nText>,
    link: "/",
    Icon: DataUsage,
    permission: () => ({})
  },
  */
  {
    name: <I18nText>Content Performance</I18nText>,
    link: "/content-performance",
    Icon: SpeedIcon,
    permission: () => ({})
  },
  {
    name: <I18nText>Content Development Audiences</I18nText>,
    link: "/content-performance-target-groups",
    Icon: PeopleIcon,
    permission: () => ({})
  },
  {
    name: <I18nText>Content Development Sport</I18nText>,
    link: "/content-performance-sport",
    Icon: SportsSoccerIcon,
    permission: () => ({})
  },
  /*
  {
    name: <I18nText>Content Clinic</I18nText>,
    link: "/content-clinic",
    Icon: LocalHospitalIcon,
    permission: () => ({})
  },
  {
    name: <I18nText>Social Alerting</I18nText>,
    link: "/social-alerting",
    Icon: FacebookIcon,
    permission: () => ({})
  },
  {
    name: <I18nText>Evergreen Booster</I18nText>,
    link: "/evergreen-booster",
    Icon: SpeedIcon,
    permission: () => ({})
  },
  {
    name: <I18nText>Audience Topic Affinity</I18nText>,
    link: "/audience-topic-affinity",
    Icon: SupervisedUserCircleIcon,
    permission: () => ({})
  },
  {
    name: <I18nText>Best Times</I18nText>,
    link: "/best-times",
    Icon: AccessTimeIcon,
    permission: () => ({})
  },
  {
    name: <I18nText>What, Where, When?</I18nText>,
    link: "/what-where-when",
    Icon: MapIcon,
    permission: () => ({})
  },
  {
    name: <I18nText>Trending Persons</I18nText>,
    link: "/trending-persons",
    Icon: PeopleIcon,
    permission: () => ({})
  },
  {
    name: <I18nText>Trending Events</I18nText>,
    link: "/trending-events",
    Icon: TodayIcon,
    permission: () => ({})
  },
  {
    name: <I18nText>Trending Consumer Goods</I18nText>,
    link: "/trending-consumer-goods",
    Icon: ShoppingBasketIcon,
    permission: () => ({})
  },
  {
    name: <I18nText>sideBar.systemManagement.title</I18nText>,
    Icon: MemoryIcon,
    link: "/system-management",
    permission: () => ({
      contextId: null,
      contextType: "system",
      targetId: null,
      targetType: "customer",
      permission: "read"
    })
  },
  {
    name: <I18nText>sideBar.customerManagement.title</I18nText>,
    Icon: GroupIcon,
    permission: ({ contextId }) => ({
      contextId: contextId,
      contextType: "customer",
      targetId: null,
      targetType: "tenant",
      permission: "read"
    }),
    items: [
      {
        name: (
          <I18nText>sideBar.customerManagement.items.tenants.title</I18nText>
        ),
        link: "/customer-management/tenants",
        permission: ({ contextId }) => ({
          contextId: contextId,
          contextType: "customer",
          targetId: null,
          targetType: "tenant",
          permission: "read"
        })
      },
      {
        name: (
          <I18nText>sideBar.customerManagement.items.accounts.title</I18nText>
        ),
        link: "/customer-management/accounts",
        permission: ({ contextId }) => ({
          contextId: contextId,
          contextType: "customer",
          targetId: contextId,
          targetType: "customer",
          permission: "read"
        })
      }
    ]
  }
  */
];

type SideBarNavProps = {
  readonly user: User;
  readonly hasPermission: (user: User, policyContrain: any) => boolean;
};

const SideBarNav = ({ user, hasPermission }: SideBarNavProps) => {
  const history = useHistory();

  const routeTo = (path: string) => {
    history.push(path || "/");
  };

  return (
    <List>
      {appMenuItems.map((item, index) => (
        <SideBarNavItem
          {...item}
          routeTo={routeTo}
          key={index}
          hasPermission={hasPermission}
          user={user}
        />
      ))}
    </List>
  );
};

export default SideBarNav;
