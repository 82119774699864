import React from "react";
import * as Yup from "yup";
import I18nText from "../../elements/I18nText";

export const initialValues = {
  name: "",
  country: "",
  city: "",
  postal_code: "",
  street: "",
  premise: ""
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(
    <I18nText>customer.name.error.isRequired</I18nText>
  ),
  country: Yup.string()
    .required(<I18nText>customer.country.error.isRequired</I18nText>)
    .min(2, <I18nText>customer.country.error.isSmall</I18nText>),
  city: Yup.string().required(
    <I18nText>customer.city.error.isRequired</I18nText>
  ),
  postal_code: Yup.string().required(
    <I18nText>customer.postalCode.error.isRequired</I18nText>
  ),
  street: Yup.string().required(
    <I18nText>customer.street.error.isRequired</I18nText>
  )
});
