import React from "react";
import PropTypes from "prop-types";
import { CardActions, TablePagination } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: "flex-end"
  }
}));

export default function CustomerTablePagination({
  loading,
  length,
  handlePageChange,
  handleRowsPerPageChange,
  page,
  rowsPerPage
}) {
  const classes = useStyles();

  if (loading) {
    return null;
  }

  return (
    <CardActions className={classes.actions}>
      <TablePagination
        component="div"
        count={length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </CardActions>
  );
}

CustomerTablePagination.propTypes = {
  loading: PropTypes.bool,
  length: PropTypes.number,
  handlePageChange: PropTypes.func,
  handleRowsPerPageChange: PropTypes.func,
  page: PropTypes.number
};
