import React from "react";
import { TableRow, TableCell, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import CreateIcon from "@material-ui/icons/Create";
import { addressObjToString } from "../../../../../../utils/stringUtils";

const useStyles = makeStyles(theme => ({
  nameContainer: {
    display: "flex",
    alignItems: "center"
  },
  link: {
    color: theme.palette.secondary.main
  },
  actionButton: {
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(2)
  }
}));
// TODO: Change static Accounts & Tenants variable to API response value
// TODO: Add link to view customer's accounts
const CustomerTableRowItem = ({ item = {}, setNavigationName }) => {
  const classes = useStyles();
  return (
    <TableRow hover key={item.id}>
      <TableCell>
        <div className={classes.nameContainer}>
          <Typography variant="body1">{item.name}</Typography>
        </div>
      </TableCell>
      <TableCell>{addressObjToString(item.address)}</TableCell>
      <TableCell>
        <Link
          className={classes.link}
          underline="always"
          to={`/customers/${item.id}/accounts`}
          onClick={() => setNavigationName(item.name)}
        >
          {"View Account(s)"}
        </Link>
      </TableCell>
      <TableCell>
        <Link
          className={classes.link}
          underline="always"
          to={`/customers/${item.id}/tenants`}
          onClick={() => setNavigationName(item.name)}
        >
          {"View Tenant(s)"}
        </Link>
      </TableCell>
      <TableCell>
        <Link
          className={classes.actionButton}
          underline="always"
          to={`customers/${item.id}/edit`}
        >
          <CreateIcon />
        </Link>
      </TableCell>
    </TableRow>
  );
};

CustomerTableRowItem.propTypes = {
  item: PropTypes.object
};

export default CustomerTableRowItem;
