import React, { Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Table } from "@material-ui/core";
import TenantsTableHead from "./components/TenantsTableHead";
import TenantsTableBody from "./components/TenantsTableBody";
import TenantsTableLoading from "./components/TenantsTableLoading";
import TenantsTablePagination from "./components/TenantsTablePagination";

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  }
}));

const TenantsTableContainer = ({
  className,
  tenants = [],
  loading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  editItemBaseUrl,
  canEditPolicy
}) => {
  const classes = useStyles();

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card className={clsx(classes.root, className)}>
      <Fragment>
        <CardContent className={classes.content}>
          <div className={classes.inner}>
            <TenantsTableLoading loading={loading} />
            <Table>
              <TenantsTableHead canEditPolicy={canEditPolicy} />
              <TenantsTableBody
                canEditPolicy={canEditPolicy}
                loading={loading}
                editItemBaseUrl={editItemBaseUrl}
                items={tenants}
              />
            </Table>
          </div>
        </CardContent>
        <TenantsTablePagination
          page={page}
          rowsPerPage={rowsPerPage}
          loading={loading}
          length={tenants.length}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </Fragment>
    </Card>
  );
};

TenantsTableContainer.propTypes = {
  className: PropTypes.string,
  tenants: PropTypes.array.isRequired,
  loading: PropTypes.bool
};

export default TenantsTableContainer;
