import React from "react";
import { Box, Paper } from "@material-ui/core";

import Unit from "./Unit";

const ReadIntensityMap = ({ metadata, units }) => {
  return (
    <>
      <Paper>
        <Box padding={1}>
          <h2>Read Intensity Map: {metadata.url}</h2>
        </Box>
      </Paper>

      <div>
        {units.map(unit => (
          <Unit key={unit.id} unit={unit} />
        ))}
      </div>
    </>
  );
};

export default ReadIntensityMap;
