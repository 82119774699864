import React, { useState, Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Tooltip,
  Fab
} from "@material-ui/core";
import I18nText from "../../elements/I18nText";
import TenantFormLoading from "./components/TenantFormLoading";
import Alert from "@material-ui/lab/Alert";
import DomainChips from "./components/DomainChips";
import { isValidURL } from "../../utils/stringUtils";
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";

import SendIcon from "@material-ui/icons/Send";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: "center"
  },
  error: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  },
  domainField: {
    marginTop: theme.spacing(3)
  },
  domainList: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5)
    }
  },
  fab: {
    margin: theme.spacing(2)
  }
}));

const TenantForm = props => {
  const {
    className,
    errors,
    touched,
    handleSubmit,
    setFieldTouched,
    handleChange,
    submitting,
    initialValues = {},
    error,
    domains,
    setDomains,
    domainItemError,
    setDomainItemError
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const [domainItem, setDomainItem] = useState("");

  const onChange = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const addDomain = () => {
    setDomainItemError(false);
    if (isValidURL(domainItem)) {
      setDomains([...domains, domainItem]);
      setDomainItem("");
    } else {
      setDomainItemError(true);
    }
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <Fragment>
      <TenantFormLoading loading={submitting} />
      <Card className={clsx(classes.root, className)}>
        {error !== null && (
          <div className={classes.error}>
            <Alert severity="error">{error}</Alert>
          </div>
        )}
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          <CardHeader
            title={
              <Fragment>
                <IconButton onClick={goBack}>
                  <ArrowBackIcon />
                </IconButton>
                <I18nText>addTenant.title</I18nText>
              </Fragment>
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label={<I18nText>tenant.name.title</I18nText>}
                  margin="dense"
                  name="name"
                  defaultValue={initialValues.name}
                  onChange={onChange.bind(null, "name")}
                  required
                  variant="outlined"
                  error={errors.name && touched.name}
                  helperText={errors.name && errors.name}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <div>
                  <DomainChips domains={domains} setDomains={setDomains} />
                </div>
                <TextField
                  className={classes.domainField}
                  label={<I18nText>tenant.domain.title</I18nText>}
                  margin="dense"
                  variant="outlined"
                  name="domain"
                  value={domainItem}
                  error={domainItemError}
                  helperText={
                    domainItemError && (
                      <I18nText>tenant.domain.error.isRequired</I18nText>
                    )
                  }
                  onChange={e => setDomainItem(e.target.value)}
                />
                <Tooltip title="Add" aria-label="add">
                  <Fab
                    color="primary"
                    onClick={addDomain}
                    className={classes.fab}
                  >
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              startIcon={<SendIcon />}
              disabled={submitting}
            >
              <I18nText>addTenant.apply</I18nText>
            </Button>
          </CardActions>
        </form>
      </Card>
    </Fragment>
  );
};

TenantForm.propTypes = {
  className: PropTypes.string
};

export default TenantForm;
