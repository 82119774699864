import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import SideBarNavItemRoot from "./components/SideBarNavItemRoot";
import SideBarNavItemChildren from "./components/SideBarNavItemChildren";
import { useLocation } from "react-router-dom";
import User from "../../../../../../models/User";

type SideBarNavItemProps = {
  readonly user: User;
  readonly hasPermission: (user: User, policyContrain: any) => boolean;
  readonly items?: { link: string }[];
  readonly routeTo: (path: string) => void;
  readonly link: string;
  readonly permission: (foo: { contextId: unknown }) => unknown;
};

const SideBarNavItem = ({
  user,
  hasPermission,
  items,
  routeTo,
  ...rest
}: SideBarNavItemProps) => {
  const location = useLocation();
  const [isExpandable] = useState(items && items.length > 0);
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
    if (!isExpandable) {
      routeTo(rest.link || "/");
    }
  };

  useEffect(() => {
    if (items && items.length > 0) {
      const links = items.map(({ link }) => link);
      links.forEach(link => {
        if (location.pathname.startsWith(link)) {
          setOpen(true);
        }
      });
    }
  }, [location, items]);

  if (!user || !hasPermission(user, rest.permission({ contextId: user.customerId }))) {
    return null;
  }

  return (
    <Fragment>
      <SideBarNavItemRoot
        {...rest}
        open={open}
        handleClick={handleClick}
        isExpandable={isExpandable}
      />
      <SideBarNavItemChildren
        user={user}
        hasPermission={hasPermission}
        open={open}
        routeTo={routeTo}
        isExpandable={isExpandable}
        items={items}
      />
    </Fragment>
  );
};

SideBarNavItem.propTypes = {
  routeTo: PropTypes.func,
  items: PropTypes.array
};

export default SideBarNavItem;
