import React from "react";
import { TOptions } from "i18next";

import { Trans } from "../i18n";

type I18nTextProps = {
  children: string;
  todo?: boolean;
  values?: string | TOptions<object>;
};

const I18nText = ({ children, todo, ...props }: I18nTextProps) => {
  if (todo) {
    return <>`[i18n]${children}`</>;
  }

  return <Trans i18nKey={children} {...props} />;
};

export default I18nText;
