import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import I18nText from "../../../../elements/I18nText";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BreadcrumbItem from "../../../BreadcrumbItems";

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const CustomersViewTooltip = ({ className, breadcrumbs }) => {
  const classes = useStyles();
  const history = useHistory();

  function goToAddCustomerForm() {
    history.push("/customers/new");
  }

  function goBack() {
    history.goBack();
  }

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <IconButton onClick={goBack}>
          <ArrowBackIcon />
        </IconButton>
        <BreadcrumbItem items={breadcrumbs} />
        <span className={classes.spacer} />
        {/** 
         *<SearchInput className={classes.searchInput} />
          <Button className={classes.exportButton}>
            <SortIcon />
          </Button>
          <Button className={classes.exportButton}>
            <FilterListIcon />
          </Button> 
        */}
        <Button
          color="secondary"
          variant="contained"
          onClick={goToAddCustomerForm}
        >
          <I18nText>addCustomer.title</I18nText>
        </Button>
      </div>
    </div>
  );
};

CustomersViewTooltip.propTypes = {
  className: PropTypes.string
};

export default CustomersViewTooltip;
