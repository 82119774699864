import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Layout from "../Layout";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const AccountsLayout = ({
  TooltipComponent,
  TableComponent,
  loading,
  isDeleting,
  deleteItem,
  accounts,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  customerId,
  totalItemsCount,
  addItemUrl,
  editItemBaseUrl,
  canAddPolicy,
  canEditPolicy,
  canDeletePolicy,
  breadcrumbs
}) => {
  const classes = useStyles();
  return (
    <Layout>
      <div className={classes.root}>
        <TooltipComponent
          customerId={customerId}
          canAddPolicy={canAddPolicy}
          addItemUrl={addItemUrl}
          breadcrumbs={breadcrumbs}
        />
        <div className={classes.content}>
          <TableComponent
            editItemBaseUrl={editItemBaseUrl}
            isDeleting={isDeleting}
            loading={loading}
            deleteItem={deleteItem}
            accounts={accounts}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalItemsCount={totalItemsCount}
            canEditPolicy={canEditPolicy}
            canDeletePolicy={canDeletePolicy}
          />
        </div>
      </div>
    </Layout>
  );
};

AccountsLayout.propTypes = {
  TooltipComponent: PropTypes.elementType,
  TableComponent: PropTypes.elementType
};

export default AccountsLayout;
