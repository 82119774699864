import React from "react";
import { Chip } from "@material-ui/core";

const DomainChips = ({ domains, setDomains }) => {
  if (!domains || domains.length < 0) {
    return null;
  }

  const handleDelete = domainToDelete => {
    let newDomains = domains.filter(domain => domain !== domainToDelete);
    setDomains(newDomains);
  };

  return (
    <div>
      {domains.map((domain, index) => (
        <Chip
          key={index}
          label={domain}
          onDelete={() => handleDelete(domain)}
        />
      ))}
    </div>
  );
};

export default DomainChips;
