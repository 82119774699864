import { useAuth } from "../../auth";

const PolicyProtectedComponent = ({ permission, component }) => {
  const { user, hasPermission } = useAuth();
  const guard = () => user && hasPermission(user, permission);

  return guard() ? component : null;
};

export default PolicyProtectedComponent;
