import React from "react";
import { useCookies } from "react-cookie";

import LocaleContext from "../context/LocaleContext";
import i18n, { DEFAULT_LOCALE } from "./i18n";

const COOKIE_NAME = "capten_locale";

const LocaleProvider = ({ config, children }) => {
  const [cookies, setCookie] = useCookies([COOKIE_NAME]);

  const updateLocale = locale => {
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    setCookie(COOKIE_NAME, locale, { path: "/", expires: oneYearFromNow });

    i18n.changeLanguage(locale);
  };

  let locale = cookies[COOKIE_NAME];
  if (locale === undefined) {
    locale = DEFAULT_LOCALE;
  }

  i18n.changeLanguage(locale);

  const contextValue = {
    locale,
    setLocale: updateLocale
  };

  return (
    <LocaleContext.Provider value={contextValue}>
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
