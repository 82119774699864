import React, { useState, useEffect } from "react";

import { useApi } from "../../api";
import ReadIntensityMap from "./ReadIntensityMap";
import { LinearProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const ReadIntensityMapContainer = ({ contentHash }) => {
  const api = useApi();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [metadata, setMetadata] = useState({
    url: ""
  });
  const [units, setUnits] = useState([]);

  useEffect(() => {
    api
      .get(`/v1/aggregated-units/${contentHash}`)
      .then(response => {
        setUnits(response.data.units);
        setMetadata({
          url: atob(contentHash)
        });

        setLoading(false);
      })
      .catch(_ => {
        setError(true);
      });
  }, [api, contentHash]);

  if (error) {
    return (
      <Alert severity="error">
        Sorry. Something went wrong. Please contact the capten team
      </Alert>
    );
  }

  if (isLoading) {
    return <LinearProgress color="secondary" />;
  }

  if (units.length === 0) {
    return (
      <Alert severity="warning">
        Sorry. There seems to be no data for this content.
      </Alert>
    );
  }

  return <ReadIntensityMap metadata={metadata} units={units} />;
};

export default ReadIntensityMapContainer;
