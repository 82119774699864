import React from "react";
import PropTypes from "prop-types";
import { LinearProgress } from "@material-ui/core";

const CustomersTableLoading = ({ loading }) => {
  if (!loading) {
    return null;
  }

  return <LinearProgress color="secondary" />;
};

CustomersTableLoading.propTypes = {
  loading: PropTypes.bool
};

export default CustomersTableLoading;
