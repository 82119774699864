const config = {
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL
      ? process.env.REACT_APP_API_BASE_URL
      : "/api"
  },
  reports: {
    params: {
      generic: {
        appIdSelected: "app_id_selected",
        appIdSignature: "app_id_sign",
        ts: "ts",
        tsSignature: "ts_sign"
      },
      byAppId: {
        "diy-jeans.de": {
          views_per_hour_selected_contents_percentile: 0.51,
          read_rate_selected_contents_avg: 0.26
        },
        "leben-und-erziehen.de": {
          views_per_hour_selected_contents_percentile: 1.56,
          read_rate_selected_contents_avg: 0.41
        }
      }
    }
  }
};

export default config;
