import { useContext } from "react";
import NavigationContext from "../context/NavigationContext";

const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    throw new Error("useNavigation must be used within an NavigationContext");
  }

  return context;
};

export default useNavigation;
