import React from "react";
import { TableRow, TableCell, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import PolicyProtectedComponent from "../../../../../PolicyProtectedComponent";

const useStyles = makeStyles(theme => ({
  nameContainer: {},
  link: {
    color: theme.palette.secondary.main
  },
  actionButton: {
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(2),
    cursor: "pointer"
  }
}));

const AccountsTableRowItem = ({
  item = {},
  deleteItem,
  editItemBaseUrl,
  canEditPolicy,
  canDeletePolicy
}) => {
  const history = useHistory();
  const classes = useStyles();

  const goToEditAccount = () => {
    history.push(`${editItemBaseUrl}accounts/${item.id}/edit`);
  };
  return (
    <TableRow hover key={item.id}>
      <TableCell>
        <div className={classes.nameContainer}>
          <Typography variant="body1">{`${item.firstName} ${item.lastName}`}</Typography>
        </div>
      </TableCell>
      <TableCell>
        <div className={classes.nameContainer}>
          <Typography variant="body1">{item.email}</Typography>
        </div>
      </TableCell>
      <TableCell>
        <div className={classes.nameContainer}>
          <Typography variant="body1">{item.gender}</Typography>
        </div>
      </TableCell>
      <TableCell>
        <PolicyProtectedComponent
          permission={canEditPolicy}
          component={
            <Link className={classes.actionButton} onClick={goToEditAccount}>
              <CreateIcon />
            </Link>
          }
        />
        <PolicyProtectedComponent
          permission={canDeletePolicy}
          component={
            <Link
              className={classes.actionButton}
              underline="always"
              onClick={() => deleteItem(item.id)}
            >
              <DeleteIcon />
            </Link>
          }
        />
      </TableCell>
    </TableRow>
  );
};

AccountsTableRowItem.propTypes = {
  item: PropTypes.object
};

export default AccountsTableRowItem;
