import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import deLocale from "date-fns/locale/de";
import I18nText from "./elements/I18nText";

import config from "./config";
import theme from "./themes/capten/theme";
import GlobalCss from "./themes/GlobalCss";

import { ApiProvider } from "./api";
import { AuthProvider } from "./auth";
import { LocaleProvider } from "./i18n";
import ErrorBoundary from "./components/ErrorBoundary";

import CustomerManagementRoute from "./routes/CustomerManagement";
import PolicyProctectedRoute from "./components/RoleProtectedRoute/PolicyProctectedRoute";
import Login from "./components/Login";
import SystemManagement from "./components/SystemManagement";
import AddCustomer from "./components/AddCustomer";
import EditCustomer from "./components/EditCustomer";
import Tenants from "./components/Tenants";
import AddTenant from "./components/AddTenant";
import EditTenant from "./components/EditTenant";
import Accounts from "./components/Accounts";
import AddAccount from "./components/AddAccount";
import EditAccount from "./components/EditAccount";
import { NavigationProvider, NAV_ID } from "./navigation";
import DataStudioReportsProvider from "./context/DataStudioReportsProvider";
import ReportPage from "./components/ReportPage";
import ReadIntensityMap from "./components/ReadIntensityMap";

const App = () => {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <GlobalCss />
        <CssBaseline />
        <LocaleProvider>
          <LocalizationProvider dateAdapter={DateFnsUtils} locale={deLocale}>
            <Router>
              <ApiProvider config={config.api}>
                <AuthProvider>
                  <DataStudioReportsProvider>
                    <NavigationProvider>
                      <Switch>
                        {/*
                        <PolicyProctectedRoute
                          permission={{}}
                          name="dashboard"
                          path="/"
                          exact
                        >
                          <ReportPage name="dashboardV2" title="Dashboard v2" />
                        </PolicyProctectedRoute>
  */}
                        <PolicyProctectedRoute
                          permission={{}}
                          name="contentPerformanceExplorer"
                          path="/content-performance"
                          exact
                        >
                          <ReportPage
                            name="contentPerformanceExplorer"
                            title="Content Performance"
                          />
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{}}
                          name="contentPerformanceExplorerSport"
                          path="/content-performance-sport"
                          exact
                        >
                          <ReportPage
                            name="contentPerformanceExplorerSport"
                            title="Content Performance Sport"
                          />
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{}}
                          name="contentPerformanceExplorerTargetGroups"
                          path="/content-performance-target-groups"
                          exact
                        >
                          <ReportPage
                            name="contentPerformanceExplorerTargetGroups"
                            title="Content Performance Target Groups"
                          />
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{}}
                          name="content-clinic"
                          path="/content-clinic"
                          exact
                        >
                          <ReportPage
                            name="contentClinic"
                            title="Content Clinic"
                          />
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{}}
                          name="evergreen-booster"
                          path="/evergreen-booster"
                          exact
                        >
                          <ReportPage
                            name="evergreenBooster"
                            title="Evergreen Booster"
                          />
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{}}
                          name="audience-topic-affinity"
                          path="/audience-topic-affinity"
                          exact
                        >
                          <ReportPage
                            name="audienceTopicAffinity"
                            title="Audience Topic Affinity"
                          />
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{}}
                          name="best-times"
                          path="/best-times"
                          exact
                        >
                          <ReportPage name="bestTimes" title="Best Times" />
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{}}
                          name="what-where-when"
                          path="/what-where-when"
                          exact
                        >
                          <ReportPage
                            name="whatWhereWhen"
                            title="What, Where, When?"
                          />
                        </PolicyProctectedRoute>
                        {/*
                        <PolicyProctectedRoute
                          permission={{}}
                          name="social-alerting"
                          path="/social-alerting"
                          exact
                        >
                          <ReportPage
                            name="socialAlerting"
                            title="Social Alerting"
                          />
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{}}
                          name="trending-persons"
                          path="/trending-persons"
                          exact
                        >
                          <ReportPage
                            name="trendingPersons"
                            title="Trending Persons"
                          />
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{}}
                          name="trending-events"
                          path="/trending-events"
                          exact
                        >
                          <ReportPage
                            name="trendingEvents"
                            title="Trending Events"
                          />
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{}}
                          name="trending-consumer-goods"
                          path="/trending-consumer-goods"
                          exact
                        >
                          <ReportPage
                            name="trendingConsumerGoods"
                            title="Trending Consumer Goods"
                          />
                        </PolicyProctectedRoute>
                        */}
                        <PolicyProctectedRoute
                          permission={{}}
                          name="read-intensity-map"
                          path="/read-intensity-map/:contentHash"
                          exact
                        >
                          {({
                            match: {
                              params: { contentHash }
                            }
                          }) => <ReadIntensityMap contentHash={contentHash} />}
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{
                            contextId: null,
                            contextType: "system",
                            targetId: null,
                            targetType: "customer",
                            permission: "update"
                          }}
                          name="edit-customer"
                          path="/customers/:customerId/edit"
                          exact
                        >
                          {({
                            match: {
                              params: { customerId }
                            }
                          }) => <EditCustomer customerId={customerId} />}
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{
                            contextId: null,
                            contextType: "system",
                            targetId: null,
                            targetType: "customer",
                            permission: "create"
                          }}
                          name="new-customer"
                          path="/customers/new"
                          exact
                        >
                          <AddCustomer />
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{
                            contextId: null,
                            contextType: "customer",
                            targetId: null,
                            targetType: "tenant",
                            permission: "read"
                          }}
                          name="view-customer-tenants"
                          path="/customers/:customerId/tenants"
                          exact
                        >
                          {({
                            match: {
                              params: { customerId }
                            }
                          }) => (
                            <Tenants
                              breadcrumbs={[
                                {
                                  title: (
                                    <I18nText>
                                      sideBar.systemManagement.title
                                    </I18nText>
                                  ),
                                  link: "/system-management"
                                },
                                {
                                  title: NAV_ID
                                },
                                {
                                  title: (
                                    <I18nText>
                                      sideBar.customerManagement.items.tenants.title
                                    </I18nText>
                                  )
                                }
                              ]}
                              canEditPolicy={{
                                contextId: null,
                                contextType: "customer",
                                targetId: null,
                                targetType: "tenant",
                                permission: "update"
                              }}
                              canAddPolicy={{
                                contextId: null,
                                contextType: "customer",
                                targetId: null,
                                targetType: "tenant",
                                permission: "create"
                              }}
                              customerId={customerId}
                              addItemUrl={`/customers/${customerId}/tenants/new`}
                            />
                          )}
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{
                            contextId: null,
                            contextType: "system",
                            targetId: null,
                            targetType: "customer",
                            permission: "create"
                          }}
                          name="new-tenant"
                          path="/customers/:customerId/tenants/new"
                          exact
                        >
                          {({
                            match: {
                              params: { customerId }
                            }
                          }) => <AddTenant customerId={customerId} />}
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{
                            contextId: null,
                            contextType: "customer",
                            targetId: null,
                            targetType: "tenant",
                            permission: "update"
                          }}
                          name="edit-tenant"
                          path="/tenants/:tenantId/edit"
                          exact
                        >
                          {({
                            match: {
                              params: { tenantId }
                            }
                          }) => <EditTenant tenantId={tenantId} />}
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{
                            contextId: null,
                            contextType: "system",
                            targetId: null,
                            targetType: "customer",
                            permission: "create"
                          }}
                          name="view-customer-accounts"
                          path="/customers/:customerId/accounts"
                          exact
                        >
                          {({
                            match: {
                              params: { customerId }
                            }
                          }) => (
                            <Accounts
                              breadcrumbs={[
                                {
                                  title: (
                                    <I18nText>
                                      sideBar.systemManagement.title
                                    </I18nText>
                                  ),
                                  link: "/system-management"
                                },
                                {
                                  title: NAV_ID
                                },
                                {
                                  title: (
                                    <I18nText>
                                      sideBar.customerManagement.items.accounts.title
                                    </I18nText>
                                  )
                                }
                              ]}
                              canEditPolicy={{
                                contextId: null,
                                contextType: "system",
                                targetId: null,
                                targetType: "customer",
                                permission: "update"
                              }}
                              canDeletePolicy={{
                                contextId: null,
                                contextType: "system",
                                targetId: null,
                                targetType: "customer",
                                permission: "delete"
                              }}
                              canAddPolicy={{
                                contextId: null,
                                contextType: "system",
                                targetId: null,
                                targetType: "customer",
                                permission: "create"
                              }}
                              customerId={customerId}
                              addItemUrl={`/customers/${customerId}/accounts/new`}
                            />
                          )}
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{
                            contextId: null,
                            contextType: "system",
                            targetId: null,
                            targetType: "customer",
                            permission: "create"
                          }}
                          name="new-account"
                          path="/customers/:customerId/accounts/new"
                          exact
                        >
                          {({
                            match: {
                              params: { customerId }
                            }
                          }) => <AddAccount customerId={customerId} />}
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{
                            contextId: null,
                            contextType: "system",
                            targetId: null,
                            targetType: "customer",
                            permission: "update"
                          }}
                          name="edit-account"
                          path="/accounts/:accountId/edit"
                          exact
                        >
                          {({
                            match: {
                              params: { accountId }
                            }
                          }) => <EditAccount accountId={accountId} />}
                        </PolicyProctectedRoute>
                        <PolicyProctectedRoute
                          permission={{
                            contextId: null,
                            contextType: "system",
                            targetId: null,
                            targetType: "customer",
                            permission: "read"
                          }}
                          name="system-management"
                          path="/system-management"
                          exact
                        >
                          <SystemManagement
                            breadcrumbs={[
                              {
                                title: (
                                  <I18nText>
                                    sideBar.systemManagement.title
                                  </I18nText>
                                )
                              }
                            ]}
                          />
                        </PolicyProctectedRoute>
                        <Route path="/customer-management">
                          {({ match: { path } }) => (
                            <CustomerManagementRoute path={path} />
                          )}
                        </Route>
                        <Route path="/login" component={Login} />
                        <Route>
                          <Redirect to="/content-performance" />
                        </Route>
                      </Switch>
                    </NavigationProvider>
                  </DataStudioReportsProvider>
                </AuthProvider>
              </ApiProvider>
            </Router>
          </LocalizationProvider>
        </LocaleProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
