import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useApi } from "../../api";
import { useAuth } from "../../auth";
import useDataStudioReports from "../../hooks/useDataStudioReports";
import Login from "./Login";

const LoginContainer = () => {
  const api = useApi();
  const { loadReports } = useDataStudioReports();
  const { setUser } = useAuth();
  const history = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailed, setLoginFailed] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = e => {
    setLoginFailed(false);
    setSubmitting(true);
    e.preventDefault();

    api
      .post("/v1/login", {
        username,
        password
      })
      .then(response => {
        setUser(response.data);
        loadReports();
        history.push("/");
      })
      .catch(() => {
        setSubmitting(false);
        setLoginFailed(true);
      });
  };

  return (
    <Login
      username={username}
      password={password}
      setUsername={setUsername}
      setPassword={setPassword}
      loginFailed={loginFailed}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit}
    />
  );
};

export default LoginContainer;
