import { createMuiTheme } from "@material-ui/core/styles";

const colors = {
  BRAND_PRIMARY: "#2e3640",
  BRAND_SECONDARY: "#e94250",

  GREY_PRIMARY: "#969a9f",
  GREY_LIGHT: "#f4f4f5"
};

const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: colors.GREY_LIGHT
        }
      }
    }
  },
  palette: {
    text: {
      primary: colors.BRAND_PRIMARY
    },
    primary: {
      main: colors.BRAND_PRIMARY
    },
    secondary: {
      main: colors.BRAND_SECONDARY
    },
    grey: {
      main: colors.GREY_PRIMARY,
      light: colors.GREY_LIGHT
    }
  },
  typography: {
    fontSize: 13,
    h2: {
      fontSize: 15,
      fontWeight: "bold"
    }
  }
});

export default theme;
