import React from "react";
import * as Yup from "yup";
import I18nText from "../../elements/I18nText";

export const initialValues = {
  name: ""
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(
    <I18nText>customer.name.error.isRequired</I18nText>
  )
});
