import React, { Fragment, useState } from "react";
import { Container, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

import TopBarContainer from "../TopBar/TopBarContainer";
import SideBarContainer from "../SideBar/SideBarContainer";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: "100%"
  },
  shiftContent: {
    paddingLeft: 280
  }
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery("(min-width:1400px)", {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const handleSidebarToggle = () => {
    setOpenSidebar(!openSidebar);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <Fragment>
      <TopBarContainer onMobileNavOpen={handleSidebarToggle} />
      <div
        className={clsx({
          [classes.root]: true,
          [classes.shiftContent]: isDesktop
        })}
      >
        <SideBarContainer
          onClose={handleSidebarClose}
          open={shouldOpenSidebar}
          variant={isDesktop ? "persistent" : "temporary"}
        />
        <Container maxWidth="xl">{children}</Container>
      </div>
    </Fragment>
  );
};

export default Layout;
