import React, { useState } from "react";
import NavigationContext from "../context/NavigationContext";

export const NAV_ID = "navigationName";

const NavigationProvider = ({ children }) => {
  const [navigationName, setNavigationName] = useState("");

  const contextValue = { navigationName, setNavigationName };

  return (
    <NavigationContext.Provider value={contextValue}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
