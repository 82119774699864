import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Layout from "../Layout";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const SystemManagementLayout = ({
  TooltipComponent,
  TableComponent,
  loading,
  customers,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalItemsCount,
  breadcrumbs
}) => {
  const classes = useStyles();
  return (
    <Layout>
      <div className={classes.root}>
        <TooltipComponent breadcrumbs={breadcrumbs} />
        <div className={classes.content}>
          <TableComponent
            loading={loading}
            customers={customers}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalItemsCount={totalItemsCount}
          />
        </div>
      </div>
    </Layout>
  );
};

SystemManagementLayout.propTypes = {
  TooltipComponent: PropTypes.elementType,
  TableComponent: PropTypes.elementType
};

export default SystemManagementLayout;
