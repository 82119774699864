import React, { useEffect, useState, useCallback } from "react";

import AuthContext from "../context/AuthContext";
import { useApi } from "../api";

export const ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  CUSTOMER_ADMIN: "CUSTOMER_ADMIN",
  USER: "USER"
};

export const TYPES = {
  CUSTOMER: "customer",
  TENANT: "tenant"
};

// const ROLE_HIERARCHY = {
//   [ROLES.USER]: [ROLES.USER]
// };

// ROLE_HIERARCHY[ROLES.ADMIN] = [ROLES.ADMIN, ...ROLE_HIERARCHY[ROLES.USER]];

// ROLE_HIERARCHY[ROLES.CUSTOMER_ADMIN] = [
//   ROLES.CUSTOMER_ADMIN,
//   ...ROLE_HIERARCHY[ROLES.USER]
// ];

// ROLE_HIERARCHY[ROLES.SUPER_ADMIN] = [
//   ROLES.SUPER_ADMIN,
//   ...ROLE_HIERARCHY[ROLES.CUSTOMER_ADMIN]
// ];

const AuthProvider = ({ children }) => {
  const api = useApi();
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const hasPermission = useCallback((user, policyContrain) => {
    try {
      const { policies } = user;

      if (Object.keys(policyContrain).length === 0) {
        return true;
      }

      const foundPolicy = policies.find(
        policy =>
          policy.contextType === policyContrain.contextType &&
          policy.contextId === policyContrain.contextId &&
          policy.targetType === policyContrain.targetType &&
          policy.targetId === policyContrain.targetId &&
          policy.permissions.includes(policyContrain.permission)
      );
      return foundPolicy !== undefined;
    } catch (error) {
      console.log(error);
      return false;
    }
  }, []);

  // const hasRole = useCallback((user, role) => {
  //   for (let i = 0; i < user.roles.length; i++) {
  //     const userRole = user.roles[i];
  //     const roleList = ROLE_HIERARCHY[userRole];

  //     if (roleList === undefined) {
  //       continue;
  //     }

  //     if (roleList.indexOf(role) !== -1) {
  //       return true;
  //     }
  //   }

  //   return false;
  // }, []);

  useEffect(() => {
    api
      .get("/v1/accounts/me")
      .then(response => {
        setUser(response.data);
      })
      .catch(() => {})
      .then(() => setLoading(false));
  }, [api]);

  const contextValue = { user, setUser, hasPermission };

  return isLoading ? null : (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
