import React from "react";
import { Container } from "@material-ui/core";

import TopBarContainer from "../TopBar/TopBarContainer";
import ReadIntensityMapContainer from "./ReadIntensityMapContainer";

const ReadIntensityMapPage = ({ contentHash }) => {
  return (
    <>
      <TopBarContainer disableHamburgerMenu={true} />
      <Container maxWidth="xl" style={{ marginTop: 75 }}>
        <ReadIntensityMapContainer contentHash={contentHash} />
      </Container>
    </>
  );
};

export default ReadIntensityMapPage;
