/* eslint-disable no-use-before-define */
import React, { Fragment, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { countries } from "./countries";
import { useTranslation } from "react-i18next";

const CountrySelect = ({ onChange, defaultValue = "", ...rest }) => {
  const [defaultCode] = useState(defaultValue);
  const { t } = useTranslation();
  const countryToFlag = isoCode => {
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, char =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  };

  const getCountryObj = code =>
    countries.find(country => country.code === code);

  return (
    <Autocomplete
      options={countries}
      onChange={(_, value) => {
        value && onChange(value.code);
      }}
      defaultValue={getCountryObj(defaultCode)}
      autoHighlight
      getOptionSelected={(option, value) => option.code === value.code}
      getOptionLabel={option => t(`countries.${option.code}`)}
      renderOption={option => (
        <Fragment>
          <span>{countryToFlag(option.code)}</span>
          {t(`countries.${option.code}`)} ({option.code}) +{option.phone}
        </Fragment>
      )}
      renderInput={params => (
        <TextField
          {...params}
          {...rest}
          variant="outlined"
          margin="dense"
          inputProps={{
            ...params.inputProps
          }}
        />
      )}
    />
  );
};

export default CountrySelect;
