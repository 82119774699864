import React from "react";
import { Box, LinearProgress, Paper } from "@material-ui/core";

import config from "../../config";
import Layout from "../Layout";
import useDataStudioReports from "../../hooks/useDataStudioReports";

const Wrapper = ({ children }) => (
  <Layout>
    <Box display="flex" padding={1}>
      <Box flexGrow={1} margin={2}>
        <Paper elevation={0}>{children}</Paper>
      </Box>
    </Box>
  </Layout>
);

const EmbeddedReport = ({ name, title }) => {
  const { isInitiallyLoading, getReportByName } = useDataStudioReports();

  if (isInitiallyLoading) {
    return (
      <Wrapper title={title}>
        <LinearProgress color="secondary" />
      </Wrapper>
    );
  }

  const report = getReportByName(name);

  if (!report) {
    return <Wrapper title={title}>Fehler: Kein Report definiert.</Wrapper>;
  }

  const reportUrl = report.url;

  const params = {
    [config.reports.params.generic.appIdSelected]: report.appId
  };

  // Workaround requested by Micha (we need additional 5 of them with affix _N)
  for (let i = 1; i < 6; i++) {
    params[`${config.reports.params.generic.appIdSelected}_${i}`] =
      report.appId;
  }

  if (report.appIdSignature) {
    params[config.reports.params.generic.appIdSignature] =
      report.appIdSignature;

    // Workaround requested by Micha (we need additional 5 of them with affix _N)
    for (let i = 1; i < 6; i++) {
      params[`${config.reports.params.generic.appIdSignature}_${i}`] =
        report.appIdSignature;
    }
  }

  if (report.ts) {
    params[config.reports.params.generic.ts] = report.ts;

    // Workaround requested by Micha (we need additional 5 of them with affix _N)
    for (let i = 1; i < 6; i++) {
      params[`${config.reports.params.generic.ts}_${i}`] = report.ts;
    }
  }

  if (report.tsSignature) {
    params[config.reports.params.generic.tsSignature] = report.tsSignature;

    // Workaround requested by Micha (we need additional 5 of them with affix _N)
    for (let i = 1; i < 6; i++) {
      params[`${config.reports.params.generic.tsSignature}_${i}`] =
        report.tsSignature;
    }
  }

  if (config.reports.params.byAppId[report.appId]) {
    for (const [key, value] of Object.entries(
      config.reports.params.byAppId[report.appId]
    )) {
      params[key] = value;
    }
  }

  const iFrameSrc = `${reportUrl}?params=${encodeURIComponent(
    JSON.stringify(params)
  )}`;

  const iframe =
    '<iframe width="100%" height="6000" src="' +
    iFrameSrc +
    '" frameborder="0" style="border:0" allowfullscreen ></iframe>';

  return (
    <Wrapper title={title}>
      <div dangerouslySetInnerHTML={{ __html: iframe }} />
    </Wrapper>
  );
};

export default EmbeddedReport;
