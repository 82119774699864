import React from "react";
import { TableRow, TableCell, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

import CreateIcon from "@material-ui/icons/Create";

import DomainPopOverItem from "./DomainPopOverItem";
import { useHistory } from "react-router-dom";
import PolicyProtectedComponent from "../../../../../PolicyProtectedComponent";

const useStyles = makeStyles(theme => ({
  nameContainer: {},
  link: {
    color: theme.palette.secondary.main
  },
  actionButton: {
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(2),
    cursor: "pointer"
  }
}));

const TenantsTableRowItem = ({ item = {}, editItemBaseUrl, canEditPolicy }) => {
  const classes = useStyles();
  const history = useHistory();

  const goToEditTenant = () => {
    history.push(`${editItemBaseUrl}tenants/${item.id}/edit`);
  };

  return (
    <TableRow hover key={item.id}>
      <TableCell>
        <div className={classes.nameContainer}>
          <Typography variant="body1">{item.name}</Typography>
        </div>
      </TableCell>
      <TableCell>
        <DomainPopOverItem items={item.domains} />
      </TableCell>
      <TableCell>
        <PolicyProtectedComponent
          permission={canEditPolicy}
          component={
            <Link className={classes.actionButton} onClick={goToEditTenant}>
              <CreateIcon />
            </Link>
          }
        />
      </TableCell>
    </TableRow>
  );
};

TenantsTableRowItem.propTypes = {
  item: PropTypes.object
};

export default TenantsTableRowItem;
