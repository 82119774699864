import React from "react";
import i18n, { TOptions } from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";

import translationDE from "./translations/de/translation.json";

export const DEFAULT_LOCALE = "de";

const resources = {
  de: {
    translation: translationDE
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LOCALE,
  fallbackLng: DEFAULT_LOCALE,
  interpolation: {
    escapeValue: false
  },
  returnEmptyString: false // @REMOVEME TODO This is for development purposes to find missing translations
});

export default i18n;

type TransProps = {
  readonly i18nKey: string;
  readonly values?: string | TOptions<object>;
};

const Trans = ({ i18nKey, values }: TransProps) => {
  const { t } = useTranslation();

  return <>{t(i18nKey, values)}</>;
};

export { Trans, useTranslation };
