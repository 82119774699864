import React from "react";
import Layout from "../Layout";

const EditAccountLayout = ({
  FormComponent,
  isSubmitting,
  onSubmit,
  error,
  customerId,
  account
}) => {
  return (
    <Layout>
      <FormComponent
        account={account}
        error={error}
        customerId={customerId}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
    </Layout>
  );
};

export default EditAccountLayout;
