import React from "react";
import { useHistory } from "react-router-dom";

import { useApi } from "../../api";
import useDataStudioReports from "../../hooks/useDataStudioReports";
import Logout from "./Logout";

const LogoutContainer = () => {
  const api = useApi();
  const history = useHistory();
  const { clearReports } = useDataStudioReports();

  const handleLogout = () => {
    api.post("/v1/logout").then(() => {
      clearReports();
      history.push("/login");
    });
  };

  return <Logout onLogout={handleLogout} />;
};

export default LogoutContainer;
