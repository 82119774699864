import React from "react";
import PropTypes from "prop-types";
import { TableBody } from "@material-ui/core";
import TenantsTableRowItem from "../AccountsTableRowItem";

const AccountsTableBody = ({
  deleteItem,
  loading,
  items = [],
  editItemBaseUrl,
  canEditPolicy,
  canDeletePolicy
}) => {
  if (loading) {
    return null;
  }

  return (
    <TableBody>
      {items.map(item => (
        <TenantsTableRowItem
          canEditPolicy={canEditPolicy}
          canDeletePolicy={canDeletePolicy}
          editItemBaseUrl={editItemBaseUrl}
          deleteItem={deleteItem}
          key={item.id}
          item={item}
        />
      ))}
    </TableBody>
  );
};

AccountsTableBody.propTypes = {
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool
};

export default AccountsTableBody;
