import React, { useState, useEffect } from "react";
import TenantsLayout from "./TenantsLayout";
import TenantsTable from "./components/TenantsTable";
import TenantsViewTooltip from "./components/TenantsViewTooltip";
import { useApi } from "../../api";

export default function TenantsContainer({
  customerId,
  addItemUrl,
  editItemBaseUrl = "/",
  canEditPolicy,
  canAddPolicy,
  breadcrumbs = []
}) {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tenants, setTenants] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const query = `page=${page + 1}&per_page=${rowsPerPage}`;
    setLoading(true);
    api
      .get(`/v1/customers/${customerId}/tenants?${query}`)
      .then(response => {
        const { data } = response;
        setTenants(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [api, page, rowsPerPage, customerId]);

  return (
    <TenantsLayout
      customerId={customerId}
      editItemBaseUrl={editItemBaseUrl}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      error={error}
      loading={loading}
      tenants={tenants}
      canEditPolicy={canEditPolicy}
      canAddPolicy={canAddPolicy}
      TooltipComponent={TenantsViewTooltip}
      TableComponent={TenantsTable}
      addItemUrl={addItemUrl}
      breadcrumbs={breadcrumbs}
    />
  );
}
