import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import AccountForm from "../AccountForm";
import { Formik } from "formik";
import { initialValues, validationSchema } from "./formik";
import I18nText from "../../elements/I18nText";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

function AddAccount({ isSubmitting, onSubmit, error }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={10}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={values => {
              onSubmit(values);
            }}
          >
            {props => (
              <AccountForm
                title={<I18nText>addAccount.title</I18nText>}
                actionText={<I18nText>addAccount.apply</I18nText>}
                error={error}
                submitting={isSubmitting}
                {...props}
              />
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );
}

AddAccount.propTypes = {};

export default AddAccount;
