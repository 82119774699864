import React, { useState } from "react";
import clsx from "clsx";
import { Popover, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  h1: {
    fontSize: 20,
    fontWeight: "bold"
  },
  h2: {
    fontSize: 18,
    fontWeight: "bold"
  },
  h3: {
    fontSize: 16,
    fontWeight: "bold"
  },
  h4: {
    fontSize: 14,
    fontWeight: "bold"
  },
  h5: {
    fontSize: 14
  },
  unit: {
    position: "absolute"
  },
  mediaUnit: {
    border: "1px solid black",
    fontWeight: "bold",
    textAlign: "center"
  },
  popover: {
    pointerEvents: "none"
  },
  paper: {
    padding: theme.spacing(1)
  }
}));

const calcBgColor = readRate => {
  const startValue = 250;
  const factor = Math.round(readRate * 100);
  const value = startValue + factor;

  return `hsla(${value}, 100%, 60%, 0.6)`;
};

const Unit = ({ unit }) => {
  const classes = useStyles();
  const [popoverAnchor, setPopoverAnchor] = useState(null);

  const handlePopoverOpen = event => {
    setPopoverAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  const isPopoverOpen = Boolean(popoverAnchor);

  const tagName = unit.tag.toLowerCase();

  const renderPopoverContent = () => {
    const readRate = Math.round(unit.readRate * 100);
    return (
      <>
        <b>Leseintensität: {readRate} %</b>
      </>
    );
  };

  const renderContent = () => {
    if (unit.type === "t") {
      // Text Unit
      return <>{unit.content}</>;
    }

    if (unit.type === "i") {
      // Media unit
      return (
        <>
          &lt;
          {tagName === "video" ? "VIDEO" : "IMAGE"}
          &gt;
        </>
      );
    }

    return "N/A";
  };

  const classNames = clsx({
    [classes["unit"]]: true,
    [classes[tagName]]: true,
    [classes["mediaUnit"]]: unit.type === "i"
  });

  return (
    <>
      <div
        className={classNames}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{
          top: parseInt(unit.dTop, 10),
          left: parseInt(unit.dLeft, 10),
          width: parseInt(unit.dWidth, 10),
          height: parseInt(unit.dHeight, 10),
          backgroundColor: calcBgColor(unit.readRate)
        }}
      >
        {renderContent()}
      </div>
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={isPopoverOpen}
        container={popoverAnchor ? popoverAnchor.parentNode : null}
        anchorEl={popoverAnchor}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {renderPopoverContent()}
      </Popover>
    </>
  );
};

export default Unit;
