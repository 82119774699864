import React, { Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from "@material-ui/core";
import CustomerFormLoading from "./components/CustomerFormLoading";
import Alert from "@material-ui/lab/Alert";
import I18nText from "../../elements/I18nText";
import CountriesSelect from "../CountriesSelect";
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";

import SendIcon from "@material-ui/icons/Send";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles(theme => ({
  root: {},
  error: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

const CustomerForm = props => {
  const {
    values,
    className,
    errors,
    touched,
    handleSubmit,
    setFieldTouched,
    handleChange,
    submitting,
    initialValues = {},
    error,
    title
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const onChange = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <Fragment>
      <CustomerFormLoading loading={submitting} />
      <Card className={clsx(classes.root, className)}>
        {error !== null && (
          <div className={classes.error}>
            <Alert severity="error">{error}</Alert>
          </div>
        )}
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          <CardHeader
            title={
              <Fragment>
                <IconButton onClick={goBack}>
                  <ArrowBackIcon />
                </IconButton>
                {title}
              </Fragment>
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label={<I18nText>customer.name.title</I18nText>}
                  margin="dense"
                  name="name"
                  defaultValue={initialValues.name}
                  onChange={onChange.bind(null, "name")}
                  required
                  variant="outlined"
                  error={errors.name && touched.name}
                  helperText={errors.name && errors.name}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CountriesSelect
                  required
                  defaultValue={initialValues.country}
                  label={<I18nText>customer.country.title</I18nText>}
                  onChange={code => {
                    values.country = code;
                  }}
                  error={errors.country && touched.country}
                  helperText={errors.country && errors.country}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={<I18nText>customer.city.title</I18nText>}
                  margin="dense"
                  name="city"
                  defaultValue={initialValues.city}
                  onChange={onChange.bind(null, "city")}
                  required
                  variant="outlined"
                  error={errors.city && touched.city}
                  helperText={errors.city && errors.city}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={<I18nText>customer.postalCode.title</I18nText>}
                  margin="dense"
                  name="postal_code"
                  onChange={onChange.bind(null, "postal_code")}
                  required
                  variant="outlined"
                  defaultValue={initialValues.postal_code}
                  error={errors.postal_code && touched.postal_code}
                  helperText={errors.postal_code && errors.postal_code}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={<I18nText>customer.street.title</I18nText>}
                  margin="dense"
                  name="street"
                  defaultValue={initialValues.street}
                  onChange={onChange.bind(null, "street")}
                  required
                  variant="outlined"
                  error={errors.street && touched.street}
                  helperText={errors.street && errors.street}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={<I18nText>customer.premise.title</I18nText>}
                  margin="dense"
                  name="premise"
                  defaultValue={initialValues.premise}
                  onChange={onChange.bind(null, "premise")}
                  required
                  variant="outlined"
                  error={errors.premise && touched.premise}
                  helperText={errors.premise && errors.premise}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              startIcon={<SendIcon />}
              disabled={submitting}
            >
              <I18nText>addCustomer.apply</I18nText>
            </Button>
          </CardActions>
        </form>
      </Card>
    </Fragment>
  );
};

CustomerForm.propTypes = {
  className: PropTypes.string
};

export default CustomerForm;
