import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Avatar, Box, Typography } from "@material-ui/core";
import knLogo from "../../../../assets/images/avatars/562b9414c01320842cebbac2730af33f.png";

const KN_USER_IDS = [
  5678927947235328 // TK
];

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content"
  },
  avatarBox: {
    width: 75,
    height: 75,
    bgcolor: "background.paper",
    borderColor: theme.palette.secondary.main,
    border: "2px solid",
    borderRadius: 50,
    padding: 6
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  },
  email: {
    color: theme.palette.grey.main,
    fontWeight: "bold"
  }
}));

const Profile = ({ className, user }) => {
  const classes = useStyles();

  let avatarSrc = null;
  if (KN_USER_IDS.indexOf(user?.id) !== -1) {
    avatarSrc = knLogo;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Box className={classes.avatarBox}>
        <Avatar
          alt={user.username}
          className={classes.avatar}
          component={RouterLink}
          src={avatarSrc}
          to="/"
        />
      </Box>
      <Typography className={classes.name} variant="h2">
        {user.username}
      </Typography>
      <Typography className={classes.email} variant="body2">
        {user.email}
      </Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object
};

export default Profile;
