import { withStyles } from "@material-ui/core";

const GlobalCss = withStyles(theme => ({
  "@global": {
    // Table headers
    ".MuiTableCell-head.MuiTableCell-root": {
      fontSize: 11,
      color: theme.palette.grey.main,
      paddingBottom: 0
    },

    // Buttons: No upper case text transform
    ".MuiButton-root": {
      textTransform: "none"
    }
  }
}))(() => null);

export default GlobalCss;
