import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.secondary.main
  },
  popover: {
    pointerEvents: "none"
  }
}));

export default function DomainPopOverItem({ items }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    if (items.length > 1) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (items.length < 1) {
    return null;
  }

  return (
    <Fragment>
      <Typography
        className={classes.link}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {`${items[0]}`}
        {items.length > 1 ? ` & ${items.length} more` : ``}
      </Typography>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box p={2}>
          <List component="nav" aria-label="main mailbox folders">
            {items.map((item, index) => (
              <ListItem button key={index}>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </Fragment>
  );
}
