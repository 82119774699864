import React from "react";
import { Collapse, Divider, List } from "@material-ui/core";
import SideBarNavItem from "../..";
import PropTypes from "prop-types";

const SideBarNavItemChildren = ({
  user,
  hasPermission,
  open,
  isExpandable,
  items,
  routeTo
}) => {
  return isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {items.map((item, index) => (
          <SideBarNavItem
            user={user}
            hasPermission={hasPermission}
            {...item}
            routeTo={routeTo}
            key={index}
          />
        ))}
      </List>
    </Collapse>
  ) : null;
};
SideBarNavItemChildren.propTypes = {
  open: PropTypes.bool.isRequired,
  isExpandable: PropTypes.bool,
  items: PropTypes.array,
  routeTo: PropTypes.func
};

export default SideBarNavItemChildren;
