import React from "react";
import { TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import I18nText from "../../../../../../elements/I18nText";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  tableHead: {
    backgroundColor: theme.palette.primary.main,
    padding: "0px",
    color: theme.palette.secondary.main
  },
  tableHeadRowItem: {
    color: theme.palette.secondary.main,
    paddingBottom: theme.spacing(2),
    fontWeight: "bold"
  }
}));

const CustomersTableHead = () => {
  const classes = useStyles();

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell>
          <Typography classes={{ root: classes.tableHeadRowItem }}>
            <I18nText>customer.name.title</I18nText>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography classes={{ root: classes.tableHeadRowItem }}>
            <I18nText>customer.address.title</I18nText>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography classes={{ root: classes.tableHeadRowItem }}>
            <I18nText>customer.accounts.title</I18nText>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography classes={{ root: classes.tableHeadRowItem }}>
            <I18nText>customer.tenants.title</I18nText>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography classes={{ root: classes.tableHeadRowItem }}>
            <I18nText>customer.action.title</I18nText>
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default CustomersTableHead;
