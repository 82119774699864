import React, { useState } from "react";
import AddAccount from "./AddAccount";
import AddAccountLayout from "./AddAccountLayout";
import { useApi } from "../../api";
import { useHistory } from "react-router-dom";
import { getPolicies } from "../../utils/permissionUtils";
import { keysToSnake } from "../../utils/stringUtils";

export default function AddAccountContainer({ customerId }) {
  const history = useHistory();
  const api = useApi();
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = values => {
    const account = Object.assign({}, values);
    account.policies = getPolicies(account.role, customerId);
    account.customer_id = customerId;
    delete account.role;
    delete account.confirmPassword;
    setSubmitting(true);
    setError(null);
    api
      .post("/v1/accounts", keysToSnake(account))
      .then(() => {
        setSubmitting(false);
        history.goBack();
      })
      .catch(error => {
        const { errors } = error.response.data;
        setSubmitting(false);
        setError(errors[0].msg);
      });
  };

  return (
    <AddAccountLayout
      error={error}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      FormComponent={AddAccount}
    />
  );
}
