import React from "react";
import PropTypes from "prop-types";
import { TableBody } from "@material-ui/core";
import CustomerTableRowItem from "../CustomerTableRowItem/CustomerTableRowItem";

const CustomersTableBody = ({ loading, items = [], setNavigationName }) => {
  if (loading) {
    return null;
  }

  return (
    <TableBody>
      {items.map(item => (
        <CustomerTableRowItem
          key={item.id}
          item={item}
          setNavigationName={setNavigationName}
        />
      ))}
    </TableBody>
  );
};

CustomersTableBody.propTypes = {
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool
};

export default CustomersTableBody;
