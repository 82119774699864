import React from "react";
import Layout from "../Layout";
import { useState } from "react";
import { useEffect } from "react";

const EditCustomerLayout = ({
  FormComponent,
  isSubmitting,
  onSubmit,
  error,
  customer
}) => {
  const [parsedCustomer, setParsedCustomer] = useState({});

  useEffect(() => {
    let parsedCustomerObj = {};
    if (Object.keys(customer).length > 0) {
      parsedCustomerObj.name = customer.name;
      parsedCustomerObj.country = customer.address.country;
      parsedCustomerObj.city = customer.address.city;
      parsedCustomerObj.postal_code = customer.address.postal_code;
      parsedCustomerObj.street = customer.address.street;
      parsedCustomerObj.premise = customer.address.premise;
      setParsedCustomer(parsedCustomerObj);
    }
  }, [customer]);
  return (
    <Layout>
      <FormComponent
        customer={parsedCustomer}
        error={error}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
    </Layout>
  );
};

export default EditCustomerLayout;
