import React, { useState } from "react";
import AddTenant from "./AddTenant";
import AddTenantLayout from "./AddTenantLayout";
import { useApi } from "../../api";
import { useHistory } from "react-router-dom";

export default function AddTenantContainer({ customerId }) {
  const history = useHistory();
  const api = useApi();
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [domains, setDomains] = useState([]);

  const onSubmit = values => {
    values.customer_id = customerId;
    setSubmitting(true);
    setError(null);
    api
      .post("/v1/tenants", values)
      .then(() => {
        setSubmitting(false);
        history.goBack();
      })
      .catch(error => {
        const { errors } = error.response.data;
        setSubmitting(false);
        errors ? setError(errors[0].msg) : setError(error.message);
      });
  };

  return (
    <AddTenantLayout
      domains={domains}
      setDomains={setDomains}
      error={error}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      FormComponent={AddTenant}
    />
  );
}
