import React, { Fragment, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select
} from "@material-ui/core";
import AccountFormLoading from "./components/AccountFormLoading";
import Alert from "@material-ui/lab/Alert";
import I18nText from "../../elements/I18nText";
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";

import SendIcon from "@material-ui/icons/Send";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { ROLES } from "../../auth/AuthProvider";

const useStyles = makeStyles(theme => ({
  root: {},
  error: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

const AccountForm = props => {
  const {
    values,
    className,
    errors,
    touched,
    handleSubmit,
    setFieldTouched,
    handleChange,
    submitting,
    initialValues = {},
    error,
    title,
    actionText
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const [optionValues, setOptionValues] = useState({
    gender: initialValues.gender,
    role: initialValues.role
  });

  const onChange = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const onOptionChange = (event, key) => {
    setOptionValues({
      ...optionValues,
      [key]: event.target.value
    });
    values[key] = event.target.value;
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <Fragment>
      <AccountFormLoading loading={submitting} />
      <Card className={clsx(classes.root, className)}>
        {error !== null && (
          <div className={classes.error}>
            <Alert severity="error">{error}</Alert>
          </div>
        )}
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          <CardHeader
            title={
              <Fragment>
                <IconButton onClick={goBack}>
                  <ArrowBackIcon />
                </IconButton>
                {title}
              </Fragment>
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={<I18nText>account.firstName.title</I18nText>}
                  margin="dense"
                  name="firstName"
                  defaultValue={initialValues.firstName}
                  onChange={onChange.bind(null, "firstName")}
                  required
                  variant="outlined"
                  error={errors.firstName && touched.firstName}
                  helperText={errors.firstName && errors.firstName}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={<I18nText>account.lastName.title</I18nText>}
                  margin="dense"
                  name="lastName"
                  defaultValue={initialValues.lastName}
                  onChange={onChange.bind(null, "lastName")}
                  required
                  variant="outlined"
                  error={errors.lastName && touched.lastName}
                  helperText={errors.lastName && errors.lastName}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label={<I18nText>account.email.title</I18nText>}
                  margin="dense"
                  name="email"
                  defaultValue={initialValues.email}
                  onChange={onChange.bind(null, "email")}
                  required
                  variant="outlined"
                  error={errors.email && touched.email}
                  helperText={errors.email && errors.email}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={<I18nText>account.password.title</I18nText>}
                  margin="dense"
                  name="password"
                  type="password"
                  onChange={onChange.bind(null, "password")}
                  required
                  variant="outlined"
                  defaultValue={initialValues.password}
                  error={errors.password && touched.password}
                  helperText={errors.password && errors.password}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={<I18nText>account.confirmPassword.title</I18nText>}
                  margin="dense"
                  name="confirmPassword"
                  type="password"
                  defaultValue={initialValues.confirmPassword}
                  onChange={onChange.bind(null, "confirmPassword")}
                  required
                  variant="outlined"
                  error={errors.confirmPassword && touched.confirmPassword}
                  helperText={errors.confirmPassword && errors.confirmPassword}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl
                  fullWidth
                  margin="dense"
                  required
                  variant="outlined"
                  className={classes.formControl}
                  error={errors.role && touched.role}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    <I18nText>account.role.title</I18nText>
                  </InputLabel>
                  <Select
                    defaultValue={initialValues.role}
                    fullWidth
                    variant="outlined"
                    native
                    onChange={e => onOptionChange(e, "role")}
                  >
                    <option aria-label="None" value="" />
                    <option value={ROLES.USER}>USER</option>
                    <option value={ROLES.CUSTOMER_ADMIN}>CUSTOMER ADMIN</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  component="fieldset"
                  error={errors.gender ? true : false}
                >
                  <FormLabel component="legend">Gender</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="gender"
                    value={optionValues.gender.toLowerCase()}
                    onChange={e => onOptionChange(e, "gender")}
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              startIcon={<SendIcon />}
              disabled={submitting}
            >
              {actionText}
            </Button>
          </CardActions>
        </form>
      </Card>
    </Fragment>
  );
};

AccountForm.propTypes = {
  className: PropTypes.string
};

export default AccountForm;
