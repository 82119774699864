import React, { useState, useEffect } from "react";
import SystemManagementLayout from "./SystemManagementLayout";
import CustomersTableContainer from "./components/CustomersTable/CustomersTableContainer";
import CustomersViewTooltip from "./components/CustomersViewTooltip/CustomersViewTooltip";
import { useApi } from "../../api";

export default function SystemManagementContainer({ breadcrumbs = [] }) {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const query = `page=${page + 1}&per_page=${rowsPerPage}`;
    setLoading(true);
    api
      .get(`/v1/customers?${query}`)
      .then(response => {
        const { data, headers } = response;
        const totalCount = parseInt(headers["x-total-count"], 10);
        setCustomers(data);
        setTotalItemsCount(totalCount);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [api, page, rowsPerPage]);

  return (
    <SystemManagementLayout
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      totalItemsCount={totalItemsCount}
      error={error}
      loading={loading}
      customers={customers}
      TooltipComponent={CustomersViewTooltip}
      TableComponent={CustomersTableContainer}
      breadcrumbs={breadcrumbs}
    />
  );
}
