import React from "react";
import { IconButton } from "@material-ui/core";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";

const Logout = ({ onLogout }) => (
  <IconButton edge="end" onClick={onLogout} color="inherit">
    <PowerSettingsNewIcon color="secondary" />
  </IconButton>
);

export default Logout;
