import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core//DialogTitle";
import { Paper } from "@material-ui/core";
import I18nText from "../../../../elements/I18nText";

function PaperComponent(props) {
  return <Paper {...props} />;
}

export default function ConfirmAccountDeletionDialog({ open, handleFeedback }) {
  const handleClose = () => {
    handleFeedback(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle>
        <I18nText>deleteAccount.title</I18nText>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <I18nText>deleteAccount.description</I18nText>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleFeedback(false)} color="primary">
          <I18nText>deleteAccount.cancel</I18nText>
        </Button>
        <Button onClick={() => handleFeedback(true)} color="primary">
          <I18nText>deleteAccount.apply</I18nText>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
