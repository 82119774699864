import React, { useState, useEffect } from "react";
import EditAccount from "./EditAccount";
import EditAccountLayout from "./EditAccountLayout";
import { useApi } from "../../api";
import { useHistory } from "react-router-dom";

export default function EditAccountContainer({ accountId }) {
  const history = useHistory();
  const api = useApi();
  const [isSubmitting, setSubmitting] = useState(false);
  const [commonUrl] = useState(`/v1/accounts/${accountId}`);
  const [account, setAccount] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    setSubmitting(true);
    api
      .get(commonUrl)
      .then(response => {
        const { data } = response;
        setAccount(data);
        setSubmitting(false);
      })
      .catch(error => {
        setError(error);
        setSubmitting(false);
      });
  }, [api, commonUrl]);

  const onSubmit = values => {
    setSubmitting(true);
    setError(null);
    api
      .put(`${commonUrl}`, values)
      .then(() => {
        setSubmitting(false);
        history.goBack();
      })
      .catch(error => {
        const { errors } = error.response.data;
        setSubmitting(false);
        errors ? setError(errors[0].msg) : setError(error.message);
      });
  };

  return (
    <EditAccountLayout
      account={account}
      error={error}
      customerId={account.customer_id}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      FormComponent={EditAccount}
    />
  );
}
