import React, { useEffect, useState } from "react";

import { useApi } from "../api";
import { useAuth } from "../auth";
import DataStudioReportsContext from "./DataStudioReportsContext";

const DataStudioReportsProvider = ({ children }) => {
  const api = useApi();
  const { user } = useAuth();

  const [reports, setReports] = useState(null);
  const [isInitiallyLoading, setInitiallyLoading] = useState(false);

  useEffect(() => {
    if (!user) {
      clearReports();

      return;
    }

    api.get("/v1/reports").then(response => setReports(response.data));
  }, [api, user]);

  const loadReports = () => {
    setInitiallyLoading(true);

    api.get("/v1/reports").then(response => {
      setReports(response.data);
      setInitiallyLoading(false);
    });
  };

  const clearReports = () => {
    setReports(null);
  };

  const getReportByName = name => {
    if (!reports) {
      return null;
    }

    return reports.find(elem => elem.name === name);
  };

  return (
    <DataStudioReportsContext.Provider
      value={{
        reports: reports || [],
        isInitiallyLoading,
        loadReports,
        clearReports,
        getReportByName
      }}
    >
      {children}
    </DataStudioReportsContext.Provider>
  );
};

export default DataStudioReportsProvider;
