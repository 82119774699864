import React from "react";
import * as Yup from "yup";
import I18nText from "../../elements/I18nText";

export const initialValues = account => ({
  ...account,
  password: "",
  confirmPassword: "",
  role:
    account && account.policies && account.policies.length > 0
      ? account.policies[0].role
      : ""
});

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(
    <I18nText>account.firstName.error.isRequired</I18nText>
  ),
  lastName: Yup.string().required(
    <I18nText>account.lastName.error.isRequired</I18nText>
  ),
  email: Yup.string()
    .required(<I18nText>account.email.error.isRequired</I18nText>)
    .email(<I18nText>account.email.error.isNotValid</I18nText>),
  password: Yup.string().min(
    8,
    <I18nText>account.password.error.isShort</I18nText>
  ),
  confirmPassword: Yup.string(
    <I18nText>account.confirmPassword.error.isRequired</I18nText>
  ).oneOf(
    [Yup.ref("password"), null],
    <I18nText>account.confirmPassword.error.isNotOneOf</I18nText>
  ),
  gender: Yup.string().required(
    <I18nText>account.gender.error.isRequired</I18nText>
  ),
  role: Yup.string().required(
    <I18nText>account.role.error.isRequired</I18nText>
  )
});
