import React from "react";
import Layout from "../Layout";

const AddCustomerLayout = ({
  FormComponent,
  isSubmitting,
  onSubmit,
  error
}) => {
  return (
    <Layout>
      <FormComponent
        error={error}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
    </Layout>
  );
};

export default AddCustomerLayout;
