import React from "react";
import Layout from "../Layout";

const EditTenantLayout = ({
  domains,
  setDomains,
  FormComponent,
  isSubmitting,
  onSubmit,
  error,
  tenant
}) => {
  return (
    <Layout>
      <FormComponent
        tenant={tenant}
        domains={domains}
        setDomains={setDomains}
        error={error}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
    </Layout>
  );
};

export default EditTenantLayout;
