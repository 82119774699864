import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(theme => ({
  menuItemTextSelected: {
    color: theme.palette.secondary.main,
    fontWeight: "bold"
  },
  menuItemText: {
    color: theme.palette.grey.main,
    fontWeight: "bold"
  },
  menuItemIcon: {
    minWidth: 35,
    marginLeft: theme.spacing(1)
  }
}));

const SideBarNavItemRoot = ({
  name,
  Icon,
  open,
  handleClick,
  isExpandable,
  link
}) => {
  const [selected, setSelected] = useState(false);
  const classes = useStyles();
  let location = useLocation();

  useEffect(() => {
    setSelected(location.pathname === link);
  }, [location, link]);

  return (
    <ListItem button onClick={handleClick}>
      {!!Icon && (
        <ListItemIcon className={classes.menuItemIcon}>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          <Typography
            type="body2"
            className={clsx(
              (open && isExpandable) || selected
                ? classes.menuItemTextSelected
                : classes.menuItemText
            )}
          >
            {name}
          </Typography>
        }
        inset={!Icon}
      />
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </ListItem>
  );
};

SideBarNavItemRoot.propTypes = {
  name: PropTypes.object,
  Icon: PropTypes.elementType,
  open: PropTypes.bool.isRequired,
  handleClick: PropTypes.func,
  isExpandable: PropTypes.bool,
  link: PropTypes.string
};

export default SideBarNavItemRoot;
