import React from "react";

import ProtectedRoute from "./ProtectedRoute";
import { useAuth } from "../../auth";

const PolicyProtectedRoute = ({ permission, ...rest }) => {
  const { user, hasPermission } = useAuth();
  const guard = () => user && hasPermission(user, permission);

  return <ProtectedRoute guard={guard} {...rest} redirectOnDeny="/login" />;
};

export default PolicyProtectedRoute;
