import React from "react";
import axios from "axios";

import ApiContext from "../context/ApiContext";
import { keysToCamel, keysToSnake } from "../utils/stringUtils";

export const getCancelTokenSource = () => {
  return axios.CancelToken.source();
};

export const isCanceled = e => axios.isCancel(e);

const ApiProvider = ({ config, children }) => {
  const options = {
    baseURL: config.baseUrl,
    timeout: 30000,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    transformRequest: [
      data => keysToSnake(data),
      ...axios.defaults.transformRequest
    ]
  };

  const apiClient = axios.create(options);

  apiClient.interceptors.response.use(
    response => {
      if (response && response.data) {
        response.data = keysToCamel(response.data);
      }

      return response;
    },
    error => {
      // TODO Do something with response error
      return Promise.reject(error);
    }
  );

  return (
    <ApiContext.Provider value={apiClient}>{children}</ApiContext.Provider>
  );
};

export default ApiProvider;
