import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import TenantForm from "../TenantForm";
import { Formik } from "formik";
import { validationSchema } from "./formik";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

function EditTenant({
  domains,
  setDomains,
  isSubmitting,
  tenant,
  onSubmit,
  error
}) {
  const classes = useStyles();
  const [domainItemError, setDomainItemError] = useState(false);
  return (
    <div className={classes.root}>
      <Grid container spacing={10}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          {Object.keys(tenant).length > 0 ? (
            <Formik
              initialValues={tenant}
              validationSchema={validationSchema}
              onSubmit={({ name }) => {
                if (domains.length < 1) {
                  setDomainItemError(true);
                } else {
                  onSubmit({ name, domains });
                }
              }}
            >
              {props => (
                <TenantForm
                  error={error}
                  domains={domains}
                  setDomains={setDomains}
                  submitting={isSubmitting}
                  domainItemError={domainItemError}
                  setDomainItemError={setDomainItemError}
                  {...props}
                />
              )}
            </Formik>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}

EditTenant.propTypes = {};

export default EditTenant;
