import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import AccountForm from "../AccountForm";
import { Formik } from "formik";
import { initialValues, validationSchema } from "./formik";
import { getPolicies } from "../../utils/permissionUtils";
import { keysToSnake } from "../../utils/stringUtils";
import I18nText from "../../elements/I18nText";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

function EditAccount({ isSubmitting, account, onSubmit, error, customerId }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={10}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          {Object.keys(account).length > 0 ? (
            <Formik
              initialValues={initialValues(account)}
              validationSchema={validationSchema}
              onSubmit={values => {
                delete values.confirmPassword;
                values.policies = getPolicies(values.role, customerId);
                onSubmit(keysToSnake(account));
              }}
            >
              {props => (
                <AccountForm
                  title={<I18nText>modifyAccount.title</I18nText>}
                  actionText={<I18nText>modifyAccount.apply</I18nText>}
                  error={error}
                  submitting={isSubmitting}
                  {...props}
                />
              )}
            </Formik>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}

EditAccount.propTypes = {};

export default EditAccount;
