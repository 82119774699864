import React, { Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Table } from "@material-ui/core";
import CustomersTableHead from "./components/CustomersTableHead";
import CustomersTableBody from "./components/CustomersTableBody/CustomersTableBody";
import CustomersTableLoading from "./components/CustomersTableLoading";
import CustomerTablePagination from "./components/CustomerTablePagination";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  }
}));

const CustomersTable = ({
  className,
  customers = [],
  loading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalItemsCount = 0,
  setNavigationName = () => {}
}) => {
  const classes = useStyles();

  const handlePageChange = (_, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card className={clsx(classes.root, className)}>
      <Fragment>
        <CardContent className={classes.content}>
          <div className={classes.inner}>
            <CustomersTableLoading loading={loading} />
            <Table>
              <CustomersTableHead />
              <CustomersTableBody
                loading={loading}
                items={customers}
                setNavigationName={setNavigationName}
              />
            </Table>
          </div>
        </CardContent>
        <CustomerTablePagination
          page={page}
          rowsPerPage={rowsPerPage}
          loading={loading}
          length={totalItemsCount}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </Fragment>
    </Card>
  );
};

CustomersTable.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired,
  loading: PropTypes.bool
};

export default CustomersTable;
