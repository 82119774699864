import { TYPES, ROLES } from "../auth/AuthProvider";

export const getPolicies = (role, cstmId) => {
  if (role === ROLES.USER) {
    return getUserPolicy(cstmId);
  } else if (role === ROLES.CUSTOMER_ADMIN) {
    return getCustomerAdminPolicy(cstmId);
  }
};

const getUserPolicy = cstmId => {
  return getCustomerContextPolicies(cstmId, ROLES.USER);
};

const getCustomerAdminPolicy = cstmId => {
  return getCustomerContextPolicies(cstmId, ROLES.CUSTOMER_ADMIN);
};

const getCustomerContextPolicies = (cstmId, role) => {
  return [
    getPolicy(TYPES.CUSTOMER, cstmId, TYPES.CUSTOMER, cstmId, role),
    getPolicy(TYPES.CUSTOMER, cstmId, TYPES.TENANT, null, role)
  ];
};

const getPolicy = (context_type, context_id, target_type, target_id, role) => {
  return { context_type, context_id, target_type, target_id, role };
};
