import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import CustomerForm from "../CustomerForm";
import { Formik } from "formik";
import { validationSchema } from "./formik";
import I18nText from "../../elements/I18nText";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

function EditCustomer({ isSubmitting, customer, onSubmit, error }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={10}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          {Object.keys(customer).length > 0 ? (
            <Formik
              initialValues={customer}
              validationSchema={validationSchema}
              onSubmit={values => {
                onSubmit(values);
              }}
            >
              {props => (
                <CustomerForm
                  title={<I18nText>editCustomer.title</I18nText>}
                  error={error}
                  submitting={isSubmitting}
                  {...props}
                />
              )}
            </Formik>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}

EditCustomer.propTypes = {};

export default EditCustomer;
