import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import TenantForm from "../TenantForm";
import { Formik } from "formik";
import { initialValues, validationSchema } from "./formik";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

function AddTenant({ domains, setDomains, isSubmitting, onSubmit, error }) {
  const classes = useStyles();
  const [domainItemError, setDomainItemError] = useState(false);
  return (
    <div className={classes.root}>
      <Grid container spacing={10}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={({ name }) => {
              if (domains.length < 1) {
                setDomainItemError(true);
              } else {
                onSubmit({ name, domains });
              }
            }}
          >
            {props => (
              <TenantForm
                error={error}
                domains={domains}
                setDomains={setDomains}
                submitting={isSubmitting}
                domainItemError={domainItemError}
                setDomainItemError={setDomainItemError}
                {...props}
              />
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );
}

AddTenant.propTypes = {};

export default AddTenant;
