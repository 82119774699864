import React, { useState } from "react";
import { Switch } from "react-router-dom";
import PolicyProctectedRoute from "../../components/RoleProtectedRoute/PolicyProctectedRoute";
import Tenants from "../../components/Tenants";
import AddTenant from "../../components/AddTenant";
import EditTenant from "../../components/EditTenant";
import Accounts from "../../components/Accounts";
import AddAccount from "../../components/AddAccount";
import EditAccount from "../../components/EditAccount";
import { useAuth } from "../../auth";
import I18nText from "../../elements/I18nText";

export default function CustomerManagementRoute({ path }) {
  const { user } = useAuth();
  const [contextId] = useState(user.customerId);

  return (
    <Switch>
      <PolicyProctectedRoute
        permission={{
          contextId,
          contextType: "customer",
          targetId: null,
          targetType: "tenant",
          permission: "read"
        }}
        name="view-customer-tenants"
        path={`${path}/tenants`}
        exact
      >
        <Tenants
          breadcrumbs={[
            {
              title: <I18nText>sideBar.customerManagement.title</I18nText>
            },
            {
              title: (
                <I18nText>
                  sideBar.customerManagement.items.tenants.title
                </I18nText>
              )
            }
          ]}
          canEditPolicy={{
            contextId,
            contextType: "customer",
            targetId: null,
            targetType: "tenant",
            permission: "update"
          }}
          canAddPolicy={{
            contextId,
            contextType: "customer",
            targetId: null,
            targetType: "tenant",
            permission: "create"
          }}
          customerId={contextId}
          addItemUrl={`${path}/tenants/new`}
          editItemBaseUrl={`${path}/`}
        />
      </PolicyProctectedRoute>
      <PolicyProctectedRoute
        permission={{
          contextId,
          contextType: "customer",
          targetId: null,
          targetType: "tenant",
          permission: "create"
        }}
        name="new-tenant"
        path={`${path}/tenants/new`}
        exact
      >
        {<AddTenant customerId={contextId} />}
      </PolicyProctectedRoute>
      <PolicyProctectedRoute
        permission={{
          contextId,
          contextType: "customer",
          targetId: contextId,
          targetType: "customer",
          permission: "read"
        }}
        name="edit-tenant"
        path={`${path}/tenants/:tenantId/edit`}
        exact
      >
        {({
          match: {
            params: { tenantId }
          }
        }) => <EditTenant tenantId={tenantId} />}
      </PolicyProctectedRoute>
      <PolicyProctectedRoute
        permission={{
          contextId,
          contextType: "customer",
          targetId: contextId,
          targetType: "customer",
          permission: "read"
        }}
        name="view-customer-accounts"
        path={`${path}/accounts`}
        exact
      >
        {
          <Accounts
            breadcrumbs={[
              {
                title: <I18nText>sideBar.customerManagement.title</I18nText>
              },
              {
                title: (
                  <I18nText>
                    sideBar.customerManagement.items.accounts.title
                  </I18nText>
                )
              }
            ]}
            canEditPolicy={{
              contextId,
              contextType: "customer",
              targetId: contextId,
              targetType: "customer",
              permission: "update"
            }}
            canDeletePolicy={{
              contextId: contextId,
              contextType: "customer",
              targetId: contextId,
              targetType: "customer",
              permission: "delete"
            }}
            canAddPolicy={{
              contextId: contextId,
              contextType: "customer",
              targetId: contextId,
              targetType: "customer",
              permission: "update"
            }}
            customerId={contextId}
            addItemUrl={`${path}/accounts/new`}
            editItemBaseUrl={`${path}/`}
          />
        }
      </PolicyProctectedRoute>
      <PolicyProctectedRoute
        permission={{
          contextId: contextId,
          contextType: "customer",
          targetId: contextId,
          targetType: "customer",
          permission: "update"
        }}
        name="new-account"
        path={`${path}/accounts/new`}
        exact
      >
        <AddAccount customerId={contextId} />
      </PolicyProctectedRoute>
      <PolicyProctectedRoute
        permission={{
          contextId,
          contextType: "customer",
          targetId: contextId,
          targetType: "tenant",
          permission: "update"
        }}
        name="edit-account"
        path={`${path}/accounts/:accountId/edit`}
        exact
      >
        {({
          match: {
            params: { accountId }
          }
        }) => <EditAccount accountId={accountId} />}
      </PolicyProctectedRoute>
    </Switch>
  );
}
