import React from "react";
import {
  Button,
  Container,
  Grid,
  TextField,
  InputAdornment,
  makeStyles,
  Box
} from "@material-ui/core";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import I18nText from "../../elements/I18nText";

// import logo from "../../assets/images/logo-dark.svg";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    width: "100vw",
    height: "100vh"
  },
  container: {
    maxWidth: 400,
    height: 450,
    textAlign: "center",
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    paddingBottom: theme.spacing(4)
  },
  button: {
    marginTop: theme.spacing(4)
  }
}));

const Login = ({
  username,
  password,
  setUsername,
  setPassword,
  onSubmit,
  loginFailed,
  isSubmitting
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Container className={classes.container}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Box marginTop={6} style={{ fontSize: 40, fontWeight: 600 }}>
              {/*
              <img width={200} height={45} src={logo} alt="capten" />
              */}
              uniten
            </Box>
            <Box marginBottom={4} style={{ fontSize: 20 }}>
              Content Performance
            </Box>

            <form noValidate>
              <TextField
                margin="normal"
                required
                fullWidth
                label={<I18nText>login.username</I18nText>}
                name="username"
                error={loginFailed}
                autoComplete="off"
                inputProps={{
                  autoCapitalize: "off"
                }}
                autoFocus
                value={username}
                onChange={e => setUsername(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlinedIcon />
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={<I18nText>login.password</I18nText>}
                type="password"
                autoComplete="current-password"
                id="password"
                error={loginFailed}
                helperText={
                  loginFailed && <I18nText>login.loginFailed</I18nText>
                }
                value={password}
                onChange={e => setPassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  )
                }}
              />
              <Button
                type="submit"
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={onSubmit}
                disabled={isSubmitting}
              >
                <I18nText>login.submit</I18nText>
              </Button>
            </form>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Login;
