import React, { useState } from "react";
import AddCustomer from "./AddCustomer";
import AddCustomerLayout from "./AddCustomerLayout";
import { useApi } from "../../api";
import { useHistory } from "react-router-dom";

export default function AddCustomerContainer() {
  const history = useHistory();
  const api = useApi();
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = values => {
    const requestObj = { address: {} };
    requestObj.name = values.name;
    delete values.name;
    Object.keys(values).map(key => (requestObj.address[key] = values[key]));
    setSubmitting(true);
    setError(null);
    api
      .post("/v1/customers", requestObj)
      .then(() => {
        setSubmitting(false);
        history.goBack();
      })
      .catch(error => {
        const { errors } = error.response.data;
        setSubmitting(false);
        errors ? setError(errors[0].msg) : setError(error.message);
      });
  };

  return (
    <AddCustomerLayout
      error={error}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      FormComponent={AddCustomer}
    />
  );
}
