import React, { useState, useEffect } from "react";
import EditTenant from "./EditTenant";
import EditTenantLayout from "./EditTenantLayout";
import { useApi } from "../../api";
import { useHistory } from "react-router-dom";

export default function EditTenantContainer({ tenantId }) {
  const history = useHistory();
  const api = useApi();
  const [isSubmitting, setSubmitting] = useState(false);
  const [commonUrl] = useState(`/v1/tenants/${tenantId}`);
  const [tenant, setTenant] = useState({});
  const [customer_id, setCustomerId] = useState(null);
  const [error, setError] = useState(null);
  const [domains, setDomains] = useState([]);

  useEffect(() => {
    setSubmitting(true);
    api
      .get(commonUrl)
      .then(response => {
        const { data } = response;
        setCustomerId(data.customerId);
        setTenant({ name: data.name });
        setDomains(data.domains);
        setSubmitting(false);
      })
      .catch(error => {
        setError(error);
        setSubmitting(false);
      });
  }, [api, commonUrl]);

  const onSubmit = values => {
    values.customer_id = customer_id;
    setSubmitting(true);
    setError(null);
    api
      .put(commonUrl, values)
      .then(() => {
        setSubmitting(false);
        history.goBack();
      })
      .catch(error => {
        const { errors } = error.response.data;
        setSubmitting(false);
        errors ? setError(errors[0].msg) : setError(error.message);
      });
  };

  return (
    <EditTenantLayout
      tenant={tenant}
      domains={domains}
      setDomains={setDomains}
      error={error}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      FormComponent={EditTenant}
    />
  );
}
