import React, { useState, useEffect } from "react";
import AccountsLayout from "./AccountsLayout";
import AccountsTable from "./components/AccountsTable";
import AccountsViewTooltip from "./components/AccountsViewTooltip";
import { useApi } from "../../api";

export default function AccountsContainer({
  customerId,
  addItemUrl,
  editItemBaseUrl = "/",
  canEditPolicy,
  canAddPolicy,
  canDeletePolicy,
  breadcrumbs = []
}) {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [page, setPage] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const query = `page=${page + 1}&per_page=${rowsPerPage}`;
    setLoading(true);
    api
      .get(`/v1/customers/${customerId}/accounts?${query}`)
      .then(response => {
        const { data, headers } = response;
        const totalCount = parseInt(headers["x-total-count"], 10);
        setAccounts(data);
        setTotalItemsCount(totalCount);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [api, page, rowsPerPage, customerId]);

  const deleteItem = id => {
    setIsDeleting(true);
    api
      .delete(`/v1/accounts/${id}`)
      .then(() => {
        setAccounts(accounts.filter(account => account.id !== id));
        setTotalItemsCount(totalItemsCount - 1);
        setIsDeleting(false);
      })
      .catch(error => {
        setError(error);
        setIsDeleting(false);
      });
  };

  return (
    <AccountsLayout
      customerId={customerId}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      totalItemsCount={totalItemsCount}
      error={error}
      loading={loading}
      isDeleting={isDeleting}
      deleteItem={deleteItem}
      accounts={accounts}
      editItemBaseUrl={editItemBaseUrl}
      TooltipComponent={AccountsViewTooltip}
      TableComponent={AccountsTable}
      addItemUrl={addItemUrl}
      canEditPolicy={canEditPolicy}
      canAddPolicy={canAddPolicy}
      canDeletePolicy={canDeletePolicy}
      breadcrumbs={breadcrumbs}
    />
  );
}
